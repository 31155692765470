import { render, staticRenderFns } from "./PointsSummaries.vue?vue&type=template&id=62100d22&"
import script from "./PointsSummaries.vue?vue&type=script&lang=js&"
export * from "./PointsSummaries.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports