<!-- eslint-disable vue/no-v-html -->
<template>
	<div class="animated fadeIn p-3">
		<div class="row">
			<div class="col-6">
				<div class="d-flex h-100">
					<b-button
						:variant="!showFilters ? 'primary' : 'secondary'"
						class="btn mx-1 bg-primary-alt mr-auto"
						@click="showFilters = !showFilters">
						<i
							class="fa fa-filter"
							aria-hidden="true" />
					</b-button>
				</div>
			</div>
		</div>
		<div>
			<hr>
			<data-filter
				:display="showFilters"
				get-by="name"
				@submit="getDataFiltered"
				@clear="clearFilters">
				<template slot="form">
					<div class="row">
						<div class="col-sm">
							<div
								class="form-group">
								<label
									for="dateRange"
									class="label">{{ translate('date_range') }}</label>
								<select
									id="dateRange"
									v-model="dateRange"
									name="dateRange"
									class="form-control">
									<option
										:value=" '' ">
										{{ translate('all') }}
									</option>
									<option
										v-for="dateRange in dateRanges"
										:key="dateRange"
										:value="dateRange">
										{{ translate(dateRange) }}
									</option>
								</select>
							</div>
						</div>
						<div
							v-if="dateRange === dateRanges.custom"
							class="col-sm">
							<label
								for="startDate"
								class="label">{{ translate('start_date') }}</label>
							<v-date-picker
								id="startDate"
								v-model="startDate"
								:input-props="{ class: 'form-control' }"
								:formats="calendarConfig"
								name="startDate"
								show-caps />
						</div>
						<div
							v-if="dateRange === dateRanges.custom"
							class="col-sm">
							<label
								for="endDate"
								class="label">{{ translate('end_date') }}</label>
							<v-date-picker
								id="endDate"
								v-model="endDate"
								:input-props="{ class: 'form-control' }"
								:formats="calendarConfig"
								name="endDate"
								show-caps />
						</div>
					</div>
				</template>
			</data-filter>
			<div
				v-if="!loading"
				class="row mb-2 d-flex text-right">
				<div class="col-md-6 col-lg-3">
					<icon-card-widget
						:medium-label="'(' + usedPoints.amount + ')'"
						:big-label="usedPoints.points"
						:small-label="translate('used_points')" />
				</div>
			</div>
			<div class="table-responsive">
				<table class="table table-hover table-striped text-nowrap">
					<thead>
						<tr>
							<th
								class="border-top-0 pointer" />
							<th class="border-top-0">
								{{ translate('category') }}
							</th>
							<th class="border-top-0 text-right pointer">
								{{ translate('description') }}
							</th>
							<th
								class="border-top-0 text-right pointer">
								{{ translate('points') }}
							</th>
							<th
								class="border-top-0 text-right pointer">
								{{ translate('amount') }}
							</th>
						</tr>
					</thead>
					<tbody v-if="!loading && hasData">
						<transition
							v-for="(item, index) in data"
							:key="index"
							name="slide-fade">
							<tr
								v-if="isParent(item) || showChild(item)"
								@click="handleRowClick(item)">
								<td>
									<em
										v-if="!isFiltering && isParent(item) && hasChildren(item)"
										:class="`fas fa-chevron-${showChild(item) ? 'up' : 'down'}`" />
								</td>
								<td
									v-if="isParent(item)"
									:class="`align-middle text-left' ${customClasses(item)}`"
									colspan="2">
									{{ item.attributes.description }}
								</td>
								<td
									v-if="!isParent(item)"
									colspan="2"
									:class="`align-middle font-weight-bold text-right ${customClasses(item)}`">
									{{ item.attributes.description }}
								</td>
								<td :class="`align-middle text-right ${customClasses(item)}`">
									{{ item.attributes.points }}
								</td>
								<td :class="`align-middle text-right ${customClasses(item)}`">
									{{ item.attributes.amount }}
								</td>
							</tr>
						</transition>
						<tr class="text-right">
							<td
								class="text-right"
								colspan="4">
								<b>{{ totals.points }}</b>
							</td>
							<td class="text-right">
								<b>{{ totals.amount }}</b>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<is-loading
				:loading-label="translate('loading')"
				:no-data-label="translate('data_not_found')"
				:loading="loading"
				:has-data="hasData" />
		</div>
	</div>
</template>
<script>
import { DATE_RANGES as dateRanges, V_CALENDAR_CONFIG } from '@/settings/Dates';
import {
	Grids, Wallets as WalletMessages,
} from '@/translations';
import FiltersParams from '@/mixins/FiltersParams';
import DataFilter from '@/components/DataFilter';
import Wallets from '@/util/Wallets';
import IconCardWidget from '@/components/IconCardWidget';
import { PAGINATION } from '@/settings/RequestReply';

export default {
	name: 'PointsSummaries',
	messages: [Grids, WalletMessages],
	components: {
		DataFilter,
		IconCardWidget,
	},
	mixins: [FiltersParams],
	data() {
		return {
			calendarConfig: V_CALENDAR_CONFIG,
			dateRanges,
			pointsSummaries: new Wallets(),
			currentExpandIds: [],
		};
	},
	computed: {
		loading() {
			return !!this.pointsSummaries.data.loading;
		},
		data() {
			try {
				const { data } = this.pointsSummaries.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		totals() {
			try {
				const { totals } = this.pointsSummaries.data.response.data.meta;
				return totals;
			} catch (error) {
				return [];
			}
		},
		usedPoints() {
			try {
				const { used } = this.pointsSummaries.data.response.data.meta;
				return used;
			} catch (error) {
				return [];
			}
		},
		hasData() {
			if (this.data) {
				const response = this.data.length;
				return !!response;
			}
			return false;
		},
		isFiltering() {
			return Object.keys(this.$route.params).some((item) => ['dateRange'].includes(item));
		},
	},
	created() {
		this.pointsSummaries.getPointsSummaries();
	},
	methods: {
		handleRowClick(item) {
			if (!item.attributes.is_parent) return;
			if (this.currentExpandIds.includes(Number(item.id))) {
				this.currentExpandIds = this.currentExpandIds.filter((id) => id !== Number(item.id));
			} else {
				this.currentExpandIds.push(Number(item.id));
			}
		},
		isParent(item) {
			return item.attributes.is_parent || this.isFiltering;
		},
		showChild(item) {
			return this.currentExpandIds.includes(Number(item.id));
		},
		hasChildren(item) {
			return item.attributes.has_children;
		},
		customClasses(item) {
			if (this.isFiltering) {
				return '';
			}
			if (this.isParent(item)) {
				return 'pointer';
			}
			return 'text-medium';
		},
		getDataFiltered(page) {
			if (typeof page === 'object') {
				this.filters = { ...this.filters, ...page };
			}
			const { query } = this.$route;
			const options = { ...query, ...this.filters };
			if (typeof page === 'number') {
				options[PAGINATION] = page;
			}
			this.pointsSummaries.getPointsSummaries(options);
		},
	},
};
</script>
